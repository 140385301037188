//import 'whatwg-fetch'

/* jQuery. Export globally */
import 'expose-loader?$!jquery';
import 'expose-loader?jQuery!jquery';

/* jQuery UJS */
import {} from 'jquery-ujs';

/* i18n */
import I18n from 'v2/client/javascripts/i18n';
/* jquery.scrollTo */
import 'jquery.scrollto';

/* ReadMore.js plugin */
import 'javascripts/common/plugins/readmore-js/readmore';


import { breakpoints, gridColumnGutters } from 'javascripts/client/media-queries';
import { lockLayout, unLockLayout } from 'javascripts/client/helpers';


/* Turbolinks 5 */
import Turbolinks from "turbolinks"
Turbolinks.start();

/* Moment */
import moment from 'moment'


/* Foundation  */
import { Foundation } from 'foundation-sites/js/foundation.core'
import { Reveal } from 'foundation-sites/js/foundation.reveal'
import { Dropdown } from 'foundation-sites/js/foundation.dropdown'
import { Abide } from 'foundation-sites/js/foundation.abide'
import { Sticky } from 'foundation-sites/js/foundation.sticky'

function validatePhoneNumber($el, required) {
  if (!required) return true;
  const phone = $el.val().replace(/\D/g, '');
  return phone.length > 4; // max area code has 4 digits
}

Foundation.addToJquery($);
Foundation.plugin(Reveal, 'Reveal');
Foundation.plugin(Dropdown, 'Dropdown');
Foundation.plugin(Abide, 'Abide');
Foundation.plugin(Sticky, 'Sticky');
Foundation.Abide.defaults.validators.phoneNumber = validatePhoneNumber;

/* Tippi tooltips */
import tippy from 'tippy.js'

/* Swiper */
// important to import from dist, cause other import breaks in safari.
import Swiper from 'swiper/dist/js/swiper.js';


/* React */
import React from 'react'
window.React = React

/* ReactDOM */
import ReactDOM from 'react-dom'
window.ReactDOM = ReactDOM

/* React UJS (call after Turbolinks) */
import ReactRailsUJS from 'react_ujs'
ReactRailsUJS.detectEvents()

/* Compose in Redux Provider */
import composeInProvider from './redux/composeInProvider';

import { clearStore } from './redux/store';

/* Common Components */
import DateInput from 'javascripts/common/components/date-input/date-input'
window.DateInput = DateInput;

import CountInput from 'javascripts/common/components/count-input/count-input'
window.CountInput = CountInput;

import DateRangeInputs from 'javascripts/common/components/date-range-inputs/date-range-inputs'
window.DateRangeInputs = DateRangeInputs;

import PhoneInput from "javascripts/common/components/phone-input";
window.PhoneInput = PhoneInput;

/* Client Components */
import Searchbox from './components/searchbox';
window.Searchbox = composeInProvider(Searchbox, 'Searchbox');

import SearchboxModal from './components/searchbox-modal'
window.SearchboxModal = composeInProvider(SearchboxModal, 'SearchboxModal');

import PropertiesFilters from './components/properties/properties-filters';
window.PropertiesFilters = composeInProvider(PropertiesFilters, 'PropertiesFilters');

import PropertylistItemPriceForm from './components/propertylist-item-price-form'
window.PropertylistItemPriceForm = composeInProvider(PropertylistItemPriceForm, 'PropertylistItemPriceForm');

import RoomModal from './components/room-modal'
window.RoomModal = RoomModal

import BookingCreatedModal from './components/booking-created-modal';
window.BookingCreatedModal = BookingCreatedModal;

import PropertyRoomSelect from './components/property-room-select';
window.PropertyRoomSelect = composeInProvider(PropertyRoomSelect, 'PropertyRoomSelect');

import PropertyInitBooking from './components/property-init-booking';
window.PropertyInitBooking = composeInProvider(PropertyInitBooking, 'PropertyInitBooking');

import PropertiesLoader from './components/properties-loader'
window.PropertiesLoader = composeInProvider(PropertiesLoader, 'PropertiesLoader');

import PropertiesCount from './components/properties/properties-count';
window.PropertiesCount = composeInProvider(PropertiesCount, 'PropertiesCount');

import BookingPayInput from './components/booking-pay-input.jsx'
window.BookingPayInput = BookingPayInput;

import PropertyViewImagesSlider from './components/property-view-images-slider'
window.PropertyViewImagesSlider = PropertyViewImagesSlider;

import BookingPropertyGuestsButton from './components/booking-property-guests-button'
window.BookingPropertyGuestsButton = BookingPropertyGuestsButton;

import BookingPaymentButton from './components/booking-payment-button'
window.BookingPaymentButton = BookingPaymentButton;

import BookingPaymentConfirmationModal from './components/booking-payment-confirmation-modal'
window.BookingPaymentConfirmationModal = BookingPaymentConfirmationModal;

import OverviewMapPanel from './components/overview-map/overview-map-panel';
window.OverviewMapPanel = composeInProvider(OverviewMapPanel, 'OverviewMapPanel');

import OverviewMapShowLink from './components/overview-map/overview-map-show-link';
window.OverviewMapShowLink = composeInProvider(OverviewMapShowLink, 'OverviewMapShowLink');

import OverviewMapModal from './components/overview-map/overview-map-modal';
window.OverviewMapModal = composeInProvider(OverviewMapModal, 'OverviewMapModal');

/* Other (not react) */
import BookingTrfForm from './booking-trf-form.js'

/* GoAlpes Event Tracker*/
import GaEt from './event-tracking.js'
window.gaEt = new GaEt();

// import initJivoSite from './jivoSite';

window.popupLegacy = function (e) {
  return window.open(e, "", "toolbar=0,location=0,directories=0,status=0,menubar=0,scrollbars=yes,resizable=1,width=600,height=580,left=200,top=200");
};


document.addEventListener("turbolinks:load", (e)=> { //DOMContentLoaded
  initApp();
});

document.addEventListener("turbolinks:before-render", ()=> {
  clearStore();
});


/* App Initializing function */
function initApp() {
  // For unlock body if modal window is opened
  unLockLayout();

  const $document = $(document);
  $document.foundation();
  // initJivoSite(I18n.locale);

  initTooltips();
  // Initialize booking trasfer form
  const bookingTrfForm = new BookingTrfForm();
  bookingTrfForm.init();
  // Selects with placeholders init
  initSelectWithPlaceHolder();
  // Selects autosubmit init
  initSelectAutosubmit();
  // Slidedown menu init
  initSlidedownMenu();
  // Slidedown submenu init
  initSlidedownSubmenu();


  // jquery.scrollTo
  $('.js-scroll-to').click((e) => {
    const $trigger = $(e.currentTarget);
    $.scrollTo($trigger.data('target'), $trigger.data('duration') || 400, $trigger.data('settings'));
  })
  $('.js-scroll-top').click((e)=>{
      e.preventDefault()
      $('html, body').animate({scrollTop:0}, 'slow')
  })

  // Open modal manually because there are 2 links for this modal and last one link gets focus on modal close.
  $('.js-write-us-modal-opener').click((e)=>{
      $('#js-write-us-modal').foundation('open')
      const $form = $('.js-write-us-modal__form')
      $form.css('display', 'block');
      $('.js-write-us-modal__form-answer').html('')
  })

  $('.js-contact-us-modal-opener').click((e)=> {
    $('#js-contact-us-modal').foundation('open')
  });

  // Faq page
  $('.js-faq__question').on('click', (e)=>{
      e.preventDefault()
      $(e.target).toggleClass( "faq__question_opened" )
      $(e.target).next('.js-faq__answer').toggle()
  })

  // AUTOSELECT INPUT CONTENT ON FOCUS
  /*$('.js-input_autoselect').bind('focus', ()=> {
      if($(this).val().length > 0) {
          $(this).get(0).setSelectionRange(0,9999);
      }
  })*/


  // Readmore plugin
  $('.js-readmore').readmore()

  // Swiper slider plugin on reviews
  const swiper = new Swiper('.js-reviews-block', {
    slidesPerView: 3,
    centeredSlides: true,
    spaceBetween: 40,
    loop: true,
    pagination: {
      el: '.js-rewiews-slider-dots',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-rewiews-slider-next',
      prevEl: '.js-rewiews-slider-prev',
    },
    breakpoints: {
      585: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 40,
        centeredSlides: false,
      }
    }
  });

  $('.js-show-more').click((event)=>{
    $(event.target).hide();
    var className = $(event.target).data('class-name');
    $(className).show();
  });

}

// Tippy tooltips
function initTooltips() {
    tippy.defaults.delay = 0
    tippy.defaults.duration = [0, 0]
    tippy.defaults.arrow = true
    tippy.defaults.animation = 'fade'
    tippy.defaults.trigger = 'mouseenter focus'
    tippy('.js-has-tip')
}

// SELECT PLACEHOLDER
function initSelectWithPlaceHolder() {
  $.each($('select.js-select_placeholder'), (i, obj)=>{
    checkSelectForPlaceHolder(obj);
  });
  $('select.js-select_placeholder').change(()=>{
    checkSelectForPlaceHolder(this);
  });
}
function checkSelectForPlaceHolder(obj) {
 if ($(obj).children('option:first-child').is(':selected')) {
   $(obj).addClass('placeholder');
 } else {
  $(obj).removeClass('placeholder');
 }
}

// SELECT AUTOSUBMIT
function initSelectAutosubmit() {
    $('.js-select_autosubmit').change((e)=>{
        e.target.form.submit()
    })
}

// Slidedown menu
function initSlidedownMenu() {
    const $smallScreenMenuLink = $('.js-top-bar__smallscreen-menu')
    $smallScreenMenuLink.on('click', ()=>{
        if($smallScreenMenuLink.hasClass('top-bar__smallscreen-menu_is-opened')) {
            unLockLayout()
        } else {
            lockLayout()
        }
        $('.js-slidedown-menu').toggleClass('slidedown-menu_is-opened')
        $smallScreenMenuLink.toggleClass('top-bar__smallscreen-menu_is-opened')
    })
}

// Slidedown submenu
function initSlidedownSubmenu() {
    $('.js-slidedown-menu__list-item-dropdown').on('click', ()=>{
        $('.js-slidedown-menu__list-item-dropdown').toggleClass('slidedown-menu__list-item_dropdown_is-opened')
    })
}
